.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 10px 12px 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: #0ea5e9;
  border-radius: 10px 10px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.image-container {
  width: 100%;
  overflow: scroll;
  white-space: nowrap;
  border: 2px solid var(--purple);
  border-top: none;
  height: 30rem;
}

.flow-img {
  width: 200%;
  height: auto;
  padding-top: 10px;
  border-bottom: 1px solid #aaa;
  border-left: 1px solid #aaa;
  border-right: 1px solid #aaa;
}
